html,
body {
  overflow-x: hidden; /* Prevent scroll on narrow devices */
  height: 100%;
  font-family: "Montserrat",sans-serif;
  line-height: 1.6;
}

.modal-open {
    overflow: initial;
}

#page-content {
  flex: 1 0 auto;
}

#sticky-footer {
  flex-shrink: unset;
}


.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  .pace-progress {
    background: $c3!important;
    position: fixed;
    z-index: 2000;
    top: 0;
    width: 100%;
    right: 100%;
    height: 2px;
  }
}


.pace-inactive {
  display: none;
}

.shadow {
    box-shadow: 0 0 1rem rgb(0 0 0 / 15%) !important;
}


.flex-1 {
    font-size: 0.8rem;
    line-height: 1.6;
    border-radius: 0.4rem;
}


@media (min-width: 576px) {
    .container,.container-sm {
        max-width:540px
    }
}

@media (min-width: 768px) {
    .container,.container-md,.container-sm {
        max-width:720px
    }
}

@media (min-width: 992px) {
    .container,.container-lg,.container-md,.container-sm {
        max-width:960px
    }
}

@media (min-width: 1200px) {
    .container,.container-lg,.container-md,.container-sm,.container-xl {
        max-width:1140px
    }
}

@media (min-width: 1400px) {
    .container,.container-lg,.container-md,.container-sm,.container-xl,.container-xxl {
        max-width:1140px
    }
}
