.search-bar {

// 4. Input + absolutely positioned label inside it

.input-label-absolute {
    position: relative;
}

.label-absolute {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: $gray-600;
}

.input-label-absolute-left {
    .label-absolute {
        left: 1.1rem;
        color: #607D8B;
    }

    .form-control {
        padding-left: 2.5rem;
    }
}

.input-label-absolute-right {
    .label-absolute {
        right: 1.1rem;
        color: #607D8B;
    }

    .form-control {
        padding-right: 2.5rem;
    }
}

}

.form-control{
    border-radius: 0.2rem;
    color: $c1;
    opacity: 1;
    &:focus {
        color:$c1;
        box-shadow: 0 0 0 0.2rem rgba(0, 179, 255, 0.25);
        border-color:#66afe9;
    }
}