.sticky-footer{    
    // background:$c2;
    
            background: #f8f9fa;
}

.footer-dark{
    background:$c3;
    background-image: url('../static/front/img/header.gif');

    .container{
        min-height: 60px;
    }

    a {
        color: rgba(255,255,255,.75);

        &:hover{
            color: #000;
        }

        &.active{
            color:#FFF;
        }
    }

    .navbar-brand {
        font-size: 18px;
        color: $c1;

        i {
            color: $gray-500;
            margin-right: 5px;
        }

        b {
            color: $gray-500;
            font-weight: bold;
        }

        @include media-breakpoint-down(xs) {
            width: 100%;
            font-size: 16px;
            margin: 0;
            text-align: center;
        }
    }
}