.text-xs {
  font-size: $font-size-xs;
}

.text-sm {
  font-size: $font-size-sm;
}

.text-base {
  font-size: $font-size-base;
}

.text-lg {
  font-size: $font-size-lg;
}

.text-xl {
  font-size: $font-size-xl;
}

.text-10 {
  font-size: 10px;
}

.text-12 {
  font-size: 12px;
}

.text-14 {
  font-size: 14px;
}

.text-16 {
  font-size: 16px;
}

.text-18 {
  font-size: 18px;
}

.text-20 {
  font-size: 20px;
}

.text-22 {
  font-size: 22px;
}

.text-24 {
  font-size: 24px;
}

.text-400 {
  font-weight: 400;
}

.text-500 {
  font-weight: 500;
}

.text-700 {
  font-weight: 700;
}

.text-shadow {
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
}

.title-box {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 3rem;
}

.title {
  color: $c2;
  line-height: 1;
  margin: 0;
  font-size: 1.7rem;
}

.subtitle {
  display: inline-block;
  position: relative;
  font-size: 1rem;
  font-weight: 300;
  text-transform: uppercase;
  color: $c3;
  margin-top: 5px;

  &:after {
    content: "";
    position: absolute;
    height: 5px;
    background: $c1;
    width: 35px;
    bottom: -0.75rem;
    left: 0;
    border-radius: 0px;
  }
}

.underline{
  display: inline-block;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    height: 5px;
    background: $c1;
    width: 35px;
    bottom: -0.75rem;
    left: 0;
    border-radius: 0px;
  }
}

.icon-rounded {
  display: flex;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  text-align: center;
  line-height: 4rem;
  color: #fff;
  background: $gray-300;
  align-content: center;
  justify-content: center;
  align-items: center;
}


//LINKS

a {
  color: $gray-500;
  text-decoration: none;
  background-color: none;

  &:hover {
    color: $gray-600;
    text-decoration: none;
  }

  &.link-arrow {
    color: $c3;
    text-decoration: none;
    display: inline-block;
    position: relative;

    &:after {
      color: $c2;
      content: "\f061";
      font-family: "Font Awesome 6 Pro";
      margin-left: -10px;
      opacity: 0;
      vertical-align: middle;
      transition: margin .3s, opacity .3s;
    }

    &:hover {
      color: $c3;

      &:after {
        margin-left: 5px;
        opacity: 1;
      }
    }

  }

}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    margin-bottom: 0.5rem;
    font-family: "Montserrat",sans-serif;
    font-weight: 700;
    line-height: 1.3;
    color: $c2;
}

.text-primary {
  color: $c1!important;
}