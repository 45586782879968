// FORM RESETS ----------------- https://github.com/necolas/normalize.css/ ---------------------------------------------

.typeahead__container {
    button,
    input,
    optgroup,
    select,
    textarea {
        font: inherit; /* 1 */
        margin: 0; /* 2 */
    }

    /**
     * Restore the font weight unset by the previous rule.
     */

    optgroup {
        font-weight: bold;
    }

    /**
     * Show the overflow in IE.
     * 1. Show the overflow in Edge.
     */

    button,
    input { /* 1 */
        overflow: visible;
    }

    /**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */

    button,
    select { /* 1 */
        text-transform: none;
    }

    /**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */

    button,
    html [type="button"], /* 1 */
    [type="reset"],
    [type="submit"] {
        -webkit-appearance: button; /* 2 */
    }

    /**
     * Remove the inner border and padding in Firefox.
     */

    button::-moz-focus-inner,
    [type="button"]::-moz-focus-inner,
    [type="reset"]::-moz-focus-inner,
    [type="submit"]::-moz-focus-inner {
        border-style: none;
        padding: 0;
    }

    /**
     * Restore the focus styles unset by the previous rule.
     */

    button:-moz-focusring,
    [type="button"]:-moz-focusring,
    [type="reset"]:-moz-focusring,
    [type="submit"]:-moz-focusring {
        outline: 1px dotted ButtonText;
    }

    /**
     * Change the border, margin, and padding in all browsers (opinionated).
     */

    fieldset {
        border: 1px solid #c0c0c0;
        margin: 0 2px;
        padding: 0.35em 0.625em 0.75em;
    }

    /**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */

    legend {
        box-sizing: border-box; /* 1 */
        color: inherit; /* 2 */
        display: table; /* 1 */
        max-width: 100%; /* 1 */
        padding: 0; /* 3 */
        white-space: normal; /* 1 */
    }

    /**
     * Remove the default vertical scrollbar in IE.
     */

    textarea {
        overflow: auto;
    }

    /**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */

    [type="checkbox"],
    [type="radio"] {
        box-sizing: border-box; /* 1 */
        padding: 0; /* 2 */
    }

    /**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */

    [type="number"]::-webkit-inner-spin-button,
    [type="number"]::-webkit-outer-spin-button {
        height: auto;
    }

    /**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */

    /**
     * Correct the text style of placeholders in Chrome, Edge, and Safari.
     */

    ::-webkit-input-placeholder {
        color: inherit;
        opacity: 0.54;
    }

    /**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */

    ::-webkit-file-upload-button {
        -webkit-appearance: button; /* 1 */
        font: inherit; /* 2 */
    }
}

// VARIABLES -----------------------------------------------------------------------------------------------------------

$typeahead-font-size: 1rem !default;
$typeahead-line-height: 1.25 !default;
$typeahead-primary-color: #66afe9 !default;
$typeahead-cancel-color: #d0021b !default;
$typeahead-radius: 2px !default;
$typeahead-shadow: false !default; // true / false
$typeahead-dropdown-spacing: 5px !default;
$typeahead-outline-color: $typeahead-primary-color !default;
$typeahead-loading-size: 1.35rem !default;

$typeahead-padding-y: 0.5rem !default;
$typeahead-padding-x: 0.75rem !default;

$typeahead-color: $c1 !default;
$typeahead-border-color: #f8f9fa !default;

$typeahead-group-color: darken($typeahead-primary-color, 30%) !default;
$typeahead-group-background: lighten($typeahead-primary-color, 30%) !default;
$typeahead-group-border-color: $gray-200 !default;

// $typeahead-item-color:#212529 !default;
$typeahead-item-color:$c1 !default;
$typeahead-item-background: #fff !default;
$typeahead-item-hover-background: #f8f9fa !default;
$typeahead-item-disabled-color: #bababa !default;
$typeahead-item-disabled-background: #fafafa !default;


$typeahead-label-color: #3a99fc !default;
$typeahead-label-font-size: calc(#{$typeahead-font-size} * 0.875) !default;
$typeahead-label-border-color: #c2e0ff !default;
$typeahead-label-background: lighten($typeahead-primary-color, 30%) !default;

// TYPEAHEAD -----------------------------------------------------------------------------------------------------------

.typeahead__ {
    &container {
        position: relative;
    }

    &container * {
        box-sizing: border-box;
        outline: 0;
    }

    &query {
        position: relative;
        z-index: 2;
        width: 100%;
    }

    &filter {
        position: relative;
        button {
            min-width: 100%;
            white-space: nowrap;
            &:after {
                display: inline-block;
                margin-left: 4px;
                width: 0;
                height: 0;
                vertical-align: -2px;
                content: "";
                border: 4px solid;
                border-right-color: transparent;
                border-bottom-color: transparent;
                border-left-color: transparent;
            }
        }
    }

    &field {
        display: flex;
        position: relative;
        width: 100%;
    }

    &button button {
        border-top-right-radius: $typeahead-radius;
        border-bottom-right-radius: $typeahead-radius;
    }

    &field {
        // #193 If applied to input, it overrides the placeholder color on IE10/11
        color: $typeahead-color;
        input,
        textarea,
        [contenteditable],
        .typeahead__hint {
            display: block;
            width: 100%;
            line-height: $typeahead-line-height;
            min-height: calc(#{$typeahead-padding-y} * 2 + 1.25rem + 2px);
            padding: $typeahead-padding-y $typeahead-padding-x;
            background: #fff;
            //border: 1px solid $typeahead-border-color;
            border-radius: $typeahead-radius 0 0 $typeahead-radius;
            appearance: none;
            box-sizing: border-box;
            @if ($typeahead-shadow) {
                box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
            }

            &:focus,
            &:active {
                //border-color: $typeahead-outline-color;
            }

            //border-color: #007eff;
            //box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1);
        }
    }

    &container.hint .typeahead__field {
        input,
        textarea,
        [contenteditable] {
            background: transparent;
        }
    }

    &container.hint .typeahead__query > :last-child,
    &hint {
        background: #fff;
    }

    &container button {
        display: inline-block;
        margin-bottom: 0;
        text-align: center;
        touch-action: manipulation;
        cursor: pointer;
        background-color: #fff;
        border: 1px solid $typeahead-border-color;
        line-height: $typeahead-line-height;
        padding: $typeahead-padding-y $typeahead-padding-x;
        user-select: none;
        color: $typeahead-color;
        @if ($typeahead-shadow) {
            box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.05);
        }

        &:hover,
        &:focus {
            color: darken($typeahead-color, 10%);
            background-color: $typeahead-item-hover-background;
            border-color: darken($typeahead-border-color, 10%);
        }

        &:active,
        &.active {
            background-image: none;
            @if ($typeahead-shadow) {
                box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
            }
        }

        &:focus,
        &:active {
            border-color: $typeahead-outline-color;
        }
    }

    &container {
        input.disabled,
        input[disabled],
        button.disabled,
        button[disabled] {
            cursor: not-allowed;
            pointer-events: none;
            opacity: 0.65;
            box-shadow: none;
            background-color: #fff;
            border-color: $typeahead-border-color;
        }

        //&.multiselect,
        //&.loading,
        //&.cancel {
        .typeahead__field {
            input,
            textarea,
            [contenteditable],
            .typeahead__hint,
            .typeahead__label-container {
                padding-right: 32px;
            }
        }
        //}
    }

    &filter,
    &button {
        z-index: 1;
        button {
            margin-left: -1px;
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
        }

        &:hover,
        &:active,
        &:focus {
            z-index: 1001;
            button {
                &:focus,
                &:active {
                    z-index: 1001;
                }
            }
        }
    }

    &filter + &button {
        button {
            margin-left: -2px;
        }
    }

    &container.filter .typeahead__filter {
        z-index: 1001;
    }

    &list,
    &dropdown {
        position: absolute;
        //top: 100%;
        left: 0;
        z-index: 1000;
        width: 100%;
        min-width: 160px;
        padding: 0;
        margin: 5px 0 0;
        list-style: none;
        text-align: left;
        background-color: #fff;
        //border: 1px solid $typeahead-border-color;
        border-radius: $typeahead-radius;
        background-clip: padding-box;
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
        @if ($typeahead-shadow) {
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
        }
    }

    &result.detached .typeahead__list {
        position: relative;
        z-index: 1041;
        top: initial;
        left: initial;
    }

    &dropdown {
        right: 0;
        left: initial;
        z-index: 1001;
    }

    &list > li {
        a{  
         padding:1rem;
         position: relative;
         border-top: solid 1px $typeahead-border-color;
        }
        &:first-child a{
            border-top: none;
        }
    }

    &list .typeahead__item,
    &dropdown .typeahead__dropdown-item {
        &[disabled] {
            > a {
                cursor: not-allowed;
                color: $typeahead-item-disabled-color;
                background-color: $typeahead-item-disabled-background;
            }
        }

        > a {
            display: block;
            padding: $typeahead-padding-y $typeahead-padding-x;
            clear: both;
            color: $typeahead-item-color;
            text-decoration: none;
        }

        &:not([disabled]) {
            > a:hover,
            > a:focus,
            &.active > a {
                background-color: $typeahead-item-hover-background;
                color: darken($typeahead-color, 10%);
            }
        }
    }

    &list.empty {
        > li {
            padding: $typeahead-padding-y $typeahead-padding-x;
            color: $typeahead-item-color;
        }
    }

    &list > .typeahead__group {
        border-color: $typeahead-group-border-color;
        font-weight: bold;

        &:first-child {
            @if ($typeahead-dropdown-spacing > 0) {
                border-top: solid 1px $typeahead-group-border-color;
            }
        }
        > a,
        > a:hover,
        > a:focus,
        &.active > a {
            cursor: default;
            color: $typeahead-group-color;
            background: $typeahead-group-background;
            display: block;
            padding: $typeahead-padding-y $typeahead-padding-x;
            clear: both;
            text-decoration: none;
        }
    }

    &list > {
        li.typeahead__group + li.typeahead__item {
            border-color: $typeahead-group-border-color;
        }
    }

    &container {
        &.result .typeahead__list,
        &.filter .typeahead__dropdown,
        &.hint .typeahead__hint,
        &.backdrop + .typeahead__backdrop {
            display: block !important;
        }
        .typeahead__list,
        .typeahead__dropdown,
        .typeahead__hint,
        + .typeahead__backdrop {
            display: none !important;
        }

        @media (min-width: 769px) {
        &.backdrop + .typeahead__backdrop{
           display:none !important;
        }
       }
    }

    &dropdown li:last-child {
        margin-top: $typeahead-dropdown-spacing;
        padding-top: $typeahead-dropdown-spacing;
        border-top: solid 1px $typeahead-border-color;
    }

    &cancel-button {
        user-select: none;
        position: absolute;
        right: 0;
        cursor: pointer;
        line-height: 1.25;
        padding: $typeahead-padding-y $typeahead-padding-x;
        visibility: hidden;
        .typeahead__label & {
            visibility: visible;
            right: 4px;
        }
    }

    &container.cancel:not(.loading),
    &label {
        .typeahead__cancel-button {
            visibility: visible;
            &:hover {
                color: $typeahead-cancel-color;
            }
        }
    }

    &search-icon {
        padding: 0 1.25rem;
        width: 16px;
        height: 16px;
        // Reference: http://www.flaticon.com/free-icon/musica-searcher_70376
        background: url(data:image/svg+xml;charset=utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTguMS4xLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDI1MC4zMTMgMjUwLjMxMyIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjUwLjMxMyAyNTAuMzEzOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCI+CjxnIGlkPSJTZWFyY2giPgoJPHBhdGggc3R5bGU9ImZpbGwtcnVsZTpldmVub2RkO2NsaXAtcnVsZTpldmVub2RkOyIgZD0iTTI0NC4xODYsMjE0LjYwNGwtNTQuMzc5LTU0LjM3OGMtMC4yODktMC4yODktMC42MjgtMC40OTEtMC45My0wLjc2ICAgYzEwLjctMTYuMjMxLDE2Ljk0NS0zNS42NiwxNi45NDUtNTYuNTU0QzIwNS44MjIsNDYuMDc1LDE1OS43NDcsMCwxMDIuOTExLDBTMCw0Ni4wNzUsMCwxMDIuOTExICAgYzAsNTYuODM1LDQ2LjA3NCwxMDIuOTExLDEwMi45MSwxMDIuOTExYzIwLjg5NSwwLDQwLjMyMy02LjI0NSw1Ni41NTQtMTYuOTQ1YzAuMjY5LDAuMzAxLDAuNDcsMC42NCwwLjc1OSwwLjkyOWw1NC4zOCw1NC4zOCAgIGM4LjE2OSw4LjE2OCwyMS40MTMsOC4xNjgsMjkuNTgzLDBDMjUyLjM1NCwyMzYuMDE3LDI1Mi4zNTQsMjIyLjc3MywyNDQuMTg2LDIxNC42MDR6IE0xMDIuOTExLDE3MC4xNDYgICBjLTM3LjEzNCwwLTY3LjIzNi0zMC4xMDItNjcuMjM2LTY3LjIzNWMwLTM3LjEzNCwzMC4xMDMtNjcuMjM2LDY3LjIzNi02Ny4yMzZjMzcuMTMyLDAsNjcuMjM1LDMwLjEwMyw2Ny4yMzUsNjcuMjM2ICAgQzE3MC4xNDYsMTQwLjA0NCwxNDAuMDQzLDE3MC4xNDYsMTAyLjkxMSwxNzAuMTQ2eiIgZmlsbD0iIzU1NTU1NSIvPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=) no-repeat scroll center center transparent;
    }

    &container.loading .typeahead__query {
        &:before,
        &:after {
            transition: all 0s linear, opacity 0.2s ease;
            position: absolute;
            z-index: 3;
            content: "";
            top: 50%;
            right: 0.55em;
            margin-top: -($typeahead-loading-size / 2);
            width: $typeahead-loading-size;
            height: $typeahead-loading-size;
            box-sizing: border-box;
            border-radius: 500rem;
            border-style: solid;
            border-width: 0.1em;
        }

        &:before {
            border-color: rgba(0, 0, 0, 0.35);
        }

        &:after {
            animation: button-spin 0.6s linear;
            animation-iteration-count: infinite;
            border-color: #fff transparent transparent;
            box-shadow: 0 0 0 1px transparent;
        }

        @keyframes button-spin {
            from {
                transform: rotate(0deg);
            }
            to {
                transform: rotate(360deg);
            }
        }
    }

    &label-container {
        list-style: none;
        position: absolute;
        padding-top: calc(#{$typeahead-font-size} * 0.375);
        padding-left: 6px;
        width: 100%;
        flex-wrap: wrap;
        display: flex;
    }

    &label {
        display: flex;
        font-size: $typeahead-label-font-size;
        position: relative;
        background: $typeahead-label-background;
        border: solid 1px $typeahead-label-border-color;
        padding-left: 4px;
        border-radius: $typeahead-radius;
        margin-right: 4px;
        margin-bottom: calc(#{$typeahead-font-size} * 0.375);

        > * {
            align-self: center;
        }

        .typeahead__cancel-button {
            line-height: normal;
            height: auto;
            position: static;
            padding-top: calc(#{$typeahead-font-size} * 0.25 - 1px);
            padding-bottom: calc(#{$typeahead-font-size} * 0.25 + 1px);
            padding-left: 6px;
            padding-right: 6px;
            margin-left: 4px;
            font-size: $typeahead-label-font-size;
            border-left: solid 1px $typeahead-label-border-color;
            &:hover {
                background-color: darken($typeahead-label-background, 5%);
            }
        }
    }
}