@import "pace";
@import "notyf";

$c1: #ff6600;
$c2: #58595b;
$c3: #000000;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
// your variables + overrides of Bootstrap variables
@import "_front/custom/_variables.scss";
@import '~bootstrap/scss/mixins';

@import "_front/components/_buttons.scss";
@import "_front/components/_burger.scss";
@import "_front/components/_forms.scss";
@import "_front/components/_cards.scss";
@import "_front/components/_typehead.scss";

@import "_front/layout/_base.scss";
@import "_front/layout/_fonts.scss";
@import "_front/layout/_navbar.scss";
@import "_front/layout/_hero.scss";
@import "_front/layout/_footer.scss";
@import "_front/layout/_sections.scss";
@import "_front/layout/_sidebar.scss";
@import "_front/layout/_responsive.scss";


.text-c1 {
    color: $c1 !important;
}

.text-c2 {
    color: $c2 !important;
}

.text-c3 {
    color: $c3 !important;
}



.slick{
.slick-slide{
    position: relative;
    margin: 20px 15px;
    box-shadow: 0 0 1rem rgb(0 0 0 / 15%) !important;
    border-radius: 20px;
    overflow: hidden;
}
}

#slick-activity {
    margin-top: 0;

    .slick2 {

      .slick-list {
        margin-top: 30px;
        @include media-breakpoint-up(lg) {
          margin-top: 0;
          margin-right: -66%;
        //   padding-right: 10%;
        }
      }
      .slick-arrow {
        z-index: 1;
        background-color: #ffffff;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        text-align: center;
        &:before {
          content: '';
          //position: absolute;
          //top: 50%;
          //left: 50%;
          //transform: translate(-50%, -50%);
        }
        &.slick-prev {
          left: 10px;
          transform: translate(-50%, -50%);
          &:before {
            display : inline-block;
            height : 0;
            width : 0;
            border-top : 8px solid transparent;
            border-right : 14px solid #000000;
            border-bottom : 8px solid transparent;
          }
        }
        &.slick-next {
          right: 10px;
          transform: translate(50%, -50%);
          &:before {
            display : inline-block;
            height : 0;
            width : 0;
            border-top : 8px solid transparent;
            border-bottom : 8px solid transparent;
            border-left : 14px solid #000000;
          }
        }
      }
    }
  }


.activity-item {
    border-radius: 20px;
    position: relative;
    overflow: hidden;
    display: inline-block;
    width: auto;

    margin: 20px 15px;
    padding: 0;
    box-shadow: 0 0 1rem rgb(0 0 0 / 15%) !important;

    @include media-breakpoint-down(md) {
      margin-bottom: 1em;
    }

    .activity-name {
      overflow: hidden;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      color: $white;
      font-size: 18px;
      font-weight: 700;
      padding: 0 20px 20px;
      margin-bottom: 0;
    }

    .activity-image{
        max-width:245px;
        max-height:380px;
    }
  }


  .photo-bg{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: contain;
    background-position: center;
    transition: transform .2s;
    background-repeat: no-repeat;
  }

/* CSS from jQuery Background Video plugin */


/**
 * Set default positioning as a fallback for if the plugin fails
 */

 .jquery-background-video-wrapper {
    position: relative;
    overflow: hidden;
    background-position: bottom center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
}

.jquery-background-video {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    top: 50%;
    left: 50%;
    -o-object-fit: contain;
    object-fit: contain;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}


/**
 * Fade in videos
 * Note the .js class - so non js users still
 * see the video
 */

.js .jquery-background-video {
    opacity: 0;
    -webkit-transition: opacity 300ms linear;
    transition: opacity 300ms linear;
}

.js .jquery-background-video.is-visible {
    opacity: 1;
}


/**
 * Pause/play button
 */

.jquery-background-video-pauseplay {
    position: absolute;
    background: transparent;
    border: none;
    box-shadow: none;
    width: 20px;
    height: 20px;
    top: 15px;
    right: 15px;
    padding: 0;
    cursor: pointer;
    outline: none !important;
    z-index: 5;
}

.jquery-background-video-pauseplay span {
    display: none;
}

.jquery-background-video-pauseplay:after,
.jquery-background-video-pauseplay:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}

.jquery-background-video-pauseplay.play:before {
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #FFF;
}

.jquery-background-video-pauseplay.pause:before,
.jquery-background-video-pauseplay.pause:after {
    border-top: 10px solid #FFF;
    border-bottom: 10px solid #FFF;
    border-left: 5px solid #FFF;
}

.jquery-background-video-pauseplay.pause:after {
    left: 10px;
}


/* ------------------------------ PAGINTION BEGIN ------------------------------ */

.pager {
    //margin: 0 0 3.75rem;
    font-size: 0;
    text-align: center;
}

.pager__item {
    display: inline-block;
    vertical-align: top;
    font-size: 1.125rem;
    font-weight: bold;
    margin: 0 2px;
    &.active .pager__link {
        background-color: #ffb74d;
        border-color: #ffb74d;
        color: #fff;
        text-decoration: none;
    }
}

.pager__item--prev svg {
    width: 8px;
    height: 12px;
}

.pager__item--next {
    svg {
        width: 8px;
        height: 12px;
    }
    .pager__link svg {
        transform: rotate(180deg);
        transform-origin: center center;
    }
}

.pager__link {
    position: relative;
    border-radius: 4px;
    display: block;
    text-align: center;
    width: 2.625rem;
    height: 2.625rem;
    line-height: 2.625rem;
    margin-left: -1px;
    color: #2f3640;
    text-decoration: none;
    transition: 0.3s;
    &:hover,
    &:focus,
    &:active {
        background-color: #ffb74d;
        border-color: #ffb74d;
        color: #fff;
        text-decoration: none;
    }
    &:hover svg path,
    &:focus svg path,
    &:active svg path {
        fill: #fff;
    }
}

.pager .pager__item {
    &.active+.pager__item .pager__link,
    &:hover+.pager__item .pager__link {
        border-left-color: #ffb74d;
    }
}

@media screen and (max-width: 576px) {
    .pager__item {
        position: absolute;
        top: -9999px;
        left: -9999px;
        &.active,
        &:first-of-type,
        &:last-of-type,
        &:nth-of-type(2),
        &:nth-last-of-type(2),
        &.active+li {
            position: initial;
            top: initial;
            left: initial;
        }
    }
}


/* ------------------------------ PAGINTION END ------------------------------ */
.search-bar {
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    background-color: #FFF;
    border: 2px solid #FFF;
    border-radius: 6px;
    -webkit-box-shadow: 0 0 14px rgb(0 0 0 / 10%);
    box-shadow: 0 0 14px rgb(0 0 0 / 10%);
    z-index: 10;
    padding: 4px;
}

.search-bar .search-input{
    position: relative;
}

.search-bar .search-input i{
    position: absolute;
    width: 20px;
    left: 15px;
    top: 18px;
    color: #d3dae0;
}

.search-bar .search-input.is-invalid i{
    color: #dc3545;
}

.search-bar .search-input {
    width: 33%;
}

.search-bar .search-input.input2 {
    width: 28%
}

.search-bar .search-input.input3 {
    width: 20%
}

.search-bar .search-input+.search-input {
    border-left: 1px solid #eee
}

.search-bar .search-input input {
    outline: 0;
    border: 0;
    height: 54px;
    width: 100%;
    padding-left: 14px;
    background-color: transparent;
    font-size: 16px;
    border-radius: 0;
    padding: 9px 4px 9px 40px;
}

.search-bar .search-button {
    width: 19%;
    height: 54px;
}

.map-bug {
    padding: 5px 10px;
    text-align: center;
    font-size: 18px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    margin: -3px auto 0 auto;
    text-align: left;

    color: #842029;
    background-color: #f8d7da;
    border-color: #f5c2c7;
}


@media (max-width: 992px) {
    .search-bar .search-button {
        width: 100%;
        border-radius: 0;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        height: 44px;
    }

    .search-bar .search-input {
        width: 100% !important;
    }

    .search-bar .search-input input {
        height: 44px;
    }

    .search-bar .search-input+.search-input {
        border-top: 1px solid #eee;
        border-left: 0;
    }
}

.iload:before {
    content: "\f3f4"!important;
}

.autocomplete-suggestions {
min-width: 11rem;
padding: 0.25rem 0;
margin: 12px 0 0 0;
font-size: 0.875rem;
color: #1e293b;
text-align: left;
list-style: none;
background-clip: padding-box;
border-radius: 4px;
overflow: auto;
cursor: pointer;
border: none;
outline: 0;

background-color: #fff;
border-radius: 2px;
background-clip: padding-box;
box-shadow: 0 6px 12px rgb(0 0 0 / 18%);


.autocomplete-suggestion {
padding: 0.4375rem 0.75rem;
// white-space: nowrap;
overflow: hidden;
border-top: solid 1px #f8f9fa;
color: $c3;
font-size: 16px;

    &:first-child{
        border-top: none;
    }
}


}

.autocomplete-selected {
text-decoration: none;
background-color: #f8f9fa;
}

.autocomplete-suggestions strong {
// font-weight: normal;
// color: #206bc4;
}

.autocomplete-group {
padding: 0.4375rem 0.75rem;
strong {
}
}

/* ------------------------------ SEARCH END ------------------------------ */

.sugest {
    a {
        color: $c3;
        &:hover {
            color: $c2;
        }
    }
}

.popup-modal {
    height: 365px;
    width: 650px;
    background-color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 45px;
    opacity: 0;
    pointer-events: none;
    transition: all 300ms ease-in-out;
    z-index: 1011;
    &.is--visible {
        opacity: 1;
        pointer-events: auto;
    }
    &__close {
        position: absolute;
        font-size: 1.2rem;
        right: -10px;
        top: -10px;
        cursor: pointer;
    }
}


.page-item{
    margin-right: 10px;
    &:last-child{
        margin-right:0;
    }
}

.page-link {
    position: relative;
    display: block;
    color: $c1;
    text-decoration: none;
    background-color: #fff;
    border: none;
    border-radius: 5px;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .page-link {
        transition: none
    }
}

.page-link:hover {
    z-index: 2;
    color: $c2;
    background-color: #e9ecef;
    border-color: #dee2e6
}

.page-link:focus {
    z-index: 3;
    color: $c2;
    background-color: #e9ecef;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(253, 177, 13, 0.25)
}

.page-item:not(:first-child) .page-link {
    margin-left: -1px
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: $c1;
    border-color: $c1
}

.page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff;
    border-color: #dee2e6
}



///
@-webkit-keyframes flip {
    from {
      -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, -360deg);
      transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, -360deg);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }

    40% {
      -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px)
        rotate3d(0, 1, 0, -190deg);
      transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px)
        rotate3d(0, 1, 0, -190deg);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }

    50% {
      -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px)
        rotate3d(0, 1, 0, -170deg);
      transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px)
        rotate3d(0, 1, 0, -170deg);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }

    80% {
      -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95) translate3d(0, 0, 0)
        rotate3d(0, 1, 0, 0deg);
      transform: perspective(400px) scale3d(0.95, 0.95, 0.95) translate3d(0, 0, 0)
        rotate3d(0, 1, 0, 0deg);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }

    to {
      -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
      transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
  }
  @keyframes flip {
    from {
      -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, -360deg);
      transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, -360deg);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }

    40% {
      -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px)
        rotate3d(0, 1, 0, -190deg);
      transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px)
        rotate3d(0, 1, 0, -190deg);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }

    50% {
      -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px)
        rotate3d(0, 1, 0, -170deg);
      transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px)
        rotate3d(0, 1, 0, -170deg);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }

    80% {
      -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95) translate3d(0, 0, 0)
        rotate3d(0, 1, 0, 0deg);
      transform: perspective(400px) scale3d(0.95, 0.95, 0.95) translate3d(0, 0, 0)
        rotate3d(0, 1, 0, 0deg);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }

    to {
      -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
      transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
  }

  .animate__animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  .animate__animated.animate__flip {
    -webkit-backface-visibility: visible;
    backface-visibility: visible;
    -webkit-animation-name: flip;
    animation-name: flip;
  }


  