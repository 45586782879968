.bg-cover {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

#hero{
    display: flex;
    align-items:center;
    @extend .bg-cover;
    &.index {
        background-image: url('../static/front/img/annuaire.jpg');
    }
}

.search-bloc {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    margin-bottom: 40px;
    position: relative;
    max-width: 1140px;
    padding-right: 15px;
    padding-left: 15px;
    z-index: 5;
    color:#000;
    text-shadow: 2px 2px 2px rgba(0,0,0,0.1);

    h1{
        font-size: 30px;
        line-height: 1.2;
        // margin-top: 1rem;
        color: #FFF;
        letter-spacing: 0.5px;
        
    }

    @include media-breakpoint-up(sm) {
        h1{
            font-size: 40px;
        }
    }

    .text-underline{
        color: $c1;
    }
}

.search_hero {
    width: 100%;
    min-height: 425px;
    max-height: 525px;
    overflow: hidden;
    position: relative;
    background-color: #fff;
    background-image: url("../static/front/img/annuaire.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content:center;

    padding-top:40px;
    padding-bottom:40px;
    @include media-breakpoint-up(sm) {
        padding-top:0;
        padding-bottom:0;
    }

  }
  .search_hero::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0.5;
    top: 0;
    left: 0;
    background: linear-gradient(-135deg, rgba(0, 153, 255, 0.7) 0, rgba(32, 32, 32, 0.7) 100%);
    z-index: 1;
  }

.bg-cover {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}



.icon-bottom {
    position: relative;
    align-self: normal;
    top: 0.5em;
}

/*
     Search bars on the homepage
    ------------------------
*/

.search-bar{
    position: relative;
    z-index: 2;

    label{
       display: none;
    }

    border-radius: .4rem;
    background: #fff;
    box-shadow: 0 3px 7px -1px rgba(0,0,0,0.1);

    .form-group {
        position: relative;
        margin-bottom: 0;

        &::after {
            display: block;
            width: 1px;
            height: 60%;
            content: '';
            background: #ddd;
            position: absolute;
            top: 20%;
            right: 0
        }

        &.no-divider,
        &:last-of-type {
            &::after {
                display: none;
            }
        }
    }
    .form-control{
        height: 58px;
        font-size: 17px;
        border-radius: 0;
        //border-color: $gray-200;
    }
    .btn-block {
        height: 58px;
        color:#fff;
        font-size:28px;
        border-radius: 10px;

    }


}

.search-bar-nav-tabs {
    border-bottom: none;
    position: relative;
    z-index: 1;

    .nav-link {
        padding: 20px 20px 35px 20px;
        margin-bottom: -15px;
        //font-weight: bold;
        border: none;
        color: #495057;
        background-color: rgba(255,255,255,0.5);
        transition: all .2s ease-out;
        border-top-left-radius: .4rem;
        border-top-right-radius: .4rem;

      &.disabled {
      }
      &.active {
        color: $c1;
      }

      @include media-breakpoint-down(sm) {
        padding: 10px 10px 25px 10px;
      }
    }
}


@include media-breakpoint-down(md) {
    .search-bar {
        border-radius: 20px;

        .form-group {
            margin-bottom: 1rem;

            &::after {
                display: block;
                width: calc(100% - 4rem);
                height: 1px;
                content: '';
                background: $gray-300;
                position: absolute;
                top: auto;
                right: auto;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        .form-control {
            padding: 10px 20px;
            margin-bottom:10px;
            height: 58px;
        }

        .input-label-absolute-left .label-absolute {
            left: .9rem;
        }

        .bootstrap-select {
            display: block !important;
            width: 100% !important;
            padding: 0;

            button {
                display: block;
                width: 100% !important;
                padding: 10px 20px;
            }
        }

    }
}
