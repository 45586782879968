//LOGO
.site-title, .site-description, .search-bar label {
    position: absolute !important;
    clip: rect(1px, 1px, 1px, 1px);
}

.navbar-dark {

    .dropdown-header {
        padding: 0;
    }

    .block-cta-pro{
        background-color: $gray-500;
        margin: 0 -1.6rem;
        padding: 11px;
        a{
        color:#000!important;   
        }
    }

    .navbar-brand {
        color: $c3;
        font-size: 24px;
        padding: 0;
        height: 60px;
        display: flex;
        align-items: center;

        i {
            color: $gray-500;
            margin-right: 5px;
        }

        b {
            color: $gray-500;
            font-weight: bold;
        }

        @include media-breakpoint-down(xs) {
            font-size: 21px;
            margin: 0;
        }
    }

    .navbar-nav {

        .nav-link-icon {
            i {
                font-size: 21px;
            }

            &:after {
                content: none;
            }
        }
        .nav-link-cta{

            color: $c3;
        }
    }


    .nav-link{
        &:not(.nav-link-icon) i{
            display: none;
           
        }
        @include media-breakpoint-down(sm) {
            width: 100%;
            display: flex;
            justify-content: space-between;

            &:hover{
            color:$c1!important;   
            }
            i {
                display: block!important;
                font-size: 19px!important;
            }
        }
    }
}

//BURGER ICON
.block-cta-mobile {
    display: flex;
    margin-left: auto;
    margin-right: -1rem;

    .btn-mobile {
        background: transparent;
        height: 60px;
        width: 60px;
        border-radius: 0;
        border: none;
        border-left: solid 1px $c1;

        &:focus {
            outline: none;
        }

        .open-menu_burger-icon {
            @include burger(21px, 2px, 5px, #000);
            display: inline-block !important;
            transition: all 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94) !important;
        }

        &[aria-expanded="true"] {
            .open-menu_burger-icon {
                @include burger-to-cross;
            }
        }

        i {
            font-size: 20px;
        }
    }
}





.navbar {
    padding-top: 0;
    padding-bottom: 0;
    overflow: visible;
    height: 60px;
    background:$c1;
    background-image: url('../static/front/img/header.gif');
}

.navbar-nav {
    margin-bottom: -2px;

    li {
        position: relative;
        align-items: center;
        align-content: center;
        display: flex;
        height: 60px;
        justify-content: space-between;
        //font-size: 1.1rem;

        .nav-link {
            padding: 0;
            color: rgba(255,255,255,.75);
        }

        &:hover:not(.active) .nav-link {
            // color:$primary!important;
            color: $c3;
            @include media-breakpoint-down(sm) {
             color: $c1;
            }
        }

        &.active {
            cursor: initial;

            .nav-link {
                color: #fff!important;
            }

            :after {
                content: "";
                position: absolute;
                height: 6px;
                left: 0;
                // background: $gray-500;
                background: $c3;
                width: 100%;
                bottom: -2px;
                border-radius: 0px;
                z-index: 9;
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .navbar-collapse {
        position: absolute;
        top: 60px;
        right: 0;
        padding: 0 1.6rem;
        width: 100%;
        background-color: #000;
        border-top: solid 1px $c1;
        z-index: 9;
    }

    .navbar-nav {
        li {
            margin-right: 0;
            font-size: 15px;

            a {
                padding: 12px 0;
            }

            &:not(:last-child) {
                margin-right: 0px;
            }

            &.active {
                :after {
                    content: none;
                }
            }

            &.dropdown{
                display: block;
                padding: 15px 0;
                width: 100%;
                height: auto;
            }

        }

        &.first {
            li {
                border-bottom: solid 1px $gray-500;
            }
        }

        li:not(:last-child) {
            border-bottom: solid 1px $gray-500;
        }
    }
}
