.btn-app {
    color: $c1;
    background-color: #f8f9fa;
    border-color: $gray-300;
    box-shadow: 0 3px 7px -1px rgba(0,0,0,0.1);
    i{
        color: $gray-400;
    }
} 

.btn {
    --bs-btn-padding-x: 1.125rem;
    --bs-btn-padding-y: 0.5rem;
    --bs-btn-font-family: inherit;
    --bs-btn-font-size: 1rem;
    --bs-btn-font-weight: 700;
    --bs-btn-line-height: 1.25;
    --bs-btn-color: #000;
    --bs-btn-letter-spacing: -0.00625rem;
    --bs-btn-bg: transparent;
    --bs-btn-border-width: 0.125rem;
    --bs-btn-border-color: transparent;
    --bs-btn-border-radius: ;
    --bs-btn-hover-border-color: initial;
    --bs-btn-box-shadow: ;
    --bs-btn-disabled-opacity: 1;
    --bs-btn-focus-box-shadow: 0 0 0 2px var(--bs-btn-focus-shadow-rgb);
    --o-icon-spacing: 0.5rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: calc(var(--bs-btn-padding-y) - 1px) var(--bs-btn-padding-x) calc(var(--bs-btn-padding-y) + 1px);
    font-family: var(--bs-btn-font-family);
    font-size: var(--bs-btn-font-size);
    font-weight: var(--bs-btn-font-weight);
    line-height: var(--bs-btn-line-height);
    color: var(--bs-btn-color);
    text-decoration: none;
    letter-spacing: var(--bs-btn-letter-spacing);
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
    background-color: var(--bs-btn-bg);
    border-radius:0!important;
}

.btn-primary, .btn-warning {
    --bs-btn-color: #000;
    --bs-btn-bg: #ff7900;
    --bs-btn-border-color: #ff7900;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #000;
    --bs-btn-hover-border-color: #000;
    --bs-btn-focus-shadow-rgb: #fff;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #fff;
    --bs-btn-active-border-color: #000;
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #ccc;
    --bs-btn-disabled-border-color: #ccc;
}

.btn-primary:focus, .btn-primary:hover,
.btn.active, .btn.show, .btn:active, .btn:focus 
.btn.hover, .btn.show, .btn:hover{
    color: #FFF;
    background-color: #000;
    border-color: #000;
}

