.blog-card {
    display: flex;
    flex-direction: column;
    margin: 1rem auto;
    box-shadow: 0 3px 7px -1px rgba(#000, .1);
    margin-bottom: 1.6%;
    background: $white;
    line-height: 1.4;
    border-radius: 5px;
    overflow: hidden;
    z-index: 0;

    a {
        color: inherit;

        &:hover {
            color: $c2;
        }
    }

    &:hover {
        .photo {
            transform: scale(1.3) rotate(3deg);
        }

    }

    .meta {
        position: relative;
        z-index: 0;
        height: 150px;
    }

    .photo {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-size: cover;
        background-position: center;
        transition: transform .2s;
    }

    .description {
        padding: 1rem;
        background: $white;
        position: relative;
        z-index: 1;

        h4 {
            line-height: 1;
            margin: 0;
            font-size: 1.3rem;
        }

        h5 {
            font-size: 1rem;
            font-weight: 300;
            text-transform: uppercase;
            color: $gray-500;
            margin-top: 5px;
            position: relative;

            &:before {
                content: "";
                position: absolute;
                height: 5px;
                background: $c2;
                width: 35px;
                bottom: -0.75rem;
                border-radius: 0px;
            }
        }

        .read-more {
            text-align: right;
        }

        &:hover {
            .read-more {
                a {
                    &:after {
                        margin-left: 5px;
                        opacity: 1;
                    }
                }
            }
        }

        &:before {
            transform: rotate(-6deg);
            content: "";
            background: #fff;
            width: 110%;
            height: 30px;
            position: absolute;
            left: 0;
            top: -10px;
            bottom: 0;
            z-index: -1;
        }
    }

    p {
        &:first-of-type {
            margin-top: 1.25rem;
        }

        position: relative;
        margin: 1rem 0 0;
    }

    @media (min-width: 640px) {
        flex-direction: row;
        max-width: 700px;

        .meta {
            flex-basis: 40%;
            height: auto;
        }


        .description {
            flex-basis: 60%;

            &:before {
                transform: skewX(-3deg);
                content: "";
                background: #fff;
                width: 30px;
                height: 100%;
                position: absolute;
                left: -10px;
                top: 0;
                bottom: 0;
                z-index: -1;
            }
        }

        &.alt {
            flex-direction: row-reverse;

            .description {
                &:before {
                    left: inherit;
                    right: -10px;
                    transform: skew(3deg)
                }
            }

            .details {
                padding-left: 25px;
            }
        }
    }
}

.mini-card {
    .meta {
        height: 90px;
    }

    .photo {
        &:after {
            position: absolute;
            text-align: center;
            left: 45%;
            top: 50%;
            transform: translate(-50%, -50%);
            content: "\f002";
            color: #ffffff;
            font-family: "Font Awesome 6 Pro";
            opacity: 0;
            vertical-align: middle;
            transition: margin .3s, opacity .3s;
            font-size: 40px;
        }
    }

    .description {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        min-height: 50px;

        h4 {
            font-size: 1.2rem;
            line-height: 1;
            margin: 0;
            color: $gray-600;
            font-weight: 300;
            letter-spacing: 1px;
        }
    }

    &:hover {
        cursor: pointer;

        .photo {
            transform: scale(1.1) rotate(3deg);

            &:after {
                margin-left: 5px;
                opacity: 1;
            }
        }
    }

    @media (min-width: 640px) {
        .meta {
            flex-basis: 48%;
        }

        .description {
            padding: 0;
            flex-basis: 52%;
        }
    }
}

.help-card{
    display: flex;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
    justify-content: space-between;
    align-items: center;
    .help-ico{
        margin: 0 1rem 0 0;
    }
    .help-txt{
        border: 1px solid rgba(0,0,0,0.125);
        border-radius: .4rem;
        padding: 0.5rem 1rem;
        width: 100%;
        p{
             text-align: justify;
             &.h5{
                text-align: left!important;
                i{
                    font-weight: 400;
                    font-style: normal;
                    color: $c1;
                }
            }
        }

    }
    &:hover{
        i{
            color:$c2;
        }
    }
}

.full-card {
    box-shadow:none;

    .meta {
        height: 300px;
    }

    .description {
        padding: 2rem 1rem;
        background: $white;
        position: relative;
        z-index: 1;

        h4 {
            line-height: 1;
            margin: 0;
            font-size: 1.7rem;
        }

        h5 {
            font-size: 1rem;
            font-weight: 300;
            text-transform: uppercase;
            color: $gray-600;
            margin-top: 5px;
        }

        .read-more {
            text-align: right;
        }

        &:before {
            transform: rotate(-4deg);
            background: #f8f9fa;
            height: 30px;
            top: -15px;
        }
    }



    &:hover {
        .photo {
            transform: scale(1.1) rotate(3deg);
        }
    }


    @media (min-width: 640px) {
        flex-direction: row;
        max-width: none;
        .meta {
            flex-basis: 40%;
            height: auto;
            padding: 3rem 0;
        }

        .description {
            padding: 3rem 0rem 2rem 4rem;
            max-width: 700px;
            flex-basis: 60%;
            &:before {
                transform: none;
                content: "";
                background: #fff;
                width: 30px;
                height: 100%;
                position: absolute;
                left: -10px;
                top: 0;
                bottom: 0;
                z-index: -1;
            }
        }
    }
}

.news-blog-card {
    .description {

        h4 {
            font-size: 1rem;
        }

        h5 {
            font-size: 0.9rem;
        }

        p {
            max-height: 65px;
            position: relative;
            overflow: hidden;
        }

    }
}

.testimonials-blog-card {
    .description {

        h4 {
            font-size: 1.1rem;
        }

        h5 {
            font-size: 0.8rem;
        }

        p {
            font-size: 0.9rem;
            position: relative;
            overflow: hidden;
            color:$gray-600;
        }

    }

    .photo{
        min-width:170px;
    }

    &:hover {
        .photo {
            transform:initial;
            transform: translateX(-30px);
        }
    }
}




.form-card{
    padding: 1.5rem 2rem;
    max-width: 500px;
    box-shadow: 0 3px 7px -1px rgba(0,0,0,0.4);
    background: #fff;
    border-radius: 5px;
    &.form-card-gradient{
        background: linear-gradient(#fff 15%,rgba(255,255,255,.65));
    }

    h2{
        line-height: 1.2;
        margin: 0;
        font-size: 1.7rem;
        font-weight:400;
    }
    .btn-lg{
        width: 100%;
        padding: 11px 22px;
        font-size: 1.1rem;
        border-radius: 4px;
        font-weight: bold;
    }


}



.modal-dialog {
    max-width: 42rem;
    margin: 1.75rem auto;
}

.google-blue {
    background: #4285f4;
}

.fb-blue {
    background:#3b5998;
}
.linkedin-blue{
    background: #4875B4;
}

.login-card{
    display: flex;
    flex-direction: column;
    margin: 1rem auto;
    box-shadow: 0 3px 7px -1px rgba(#000, .3);
    margin-bottom: 1.6%;
    background: $white;
    line-height: 1.4;
    border-radius: 5px;
    overflow: hidden;
    z-index: 0;

    .meta{
        position: relative;
        z-index: 0;
        background-image: url(https://images.unsplash.com/photo-1477346611705-65d1883cee1e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80);
        background-size: cover;
        background-position:center;
        h3{
            color:#fff;
        }
    }
    .form{
        z-index: 1;
        color:$gray-500;
        h3{
            color:$gray-700;
            font-size: 1.1rem;
            span{
                color:$gray-400;
                font-weight: 400;
            }
        }

        a{
            &.ico{
                width: 2.5rem;
                height: 2.5rem;
                border-radius: 2.5rem;
                color:$gray-100 ;
                box-shadow: 0 3px 7px -1px rgba(#000, .1);
                font-weight: bold;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                svg{
                    fill: currentColor;
                    width: 1.6rem;
                    height: 1.6rem;
                }
                &:hover{
                    color: #fff;
                }
            }

        }
    }

    @media (min-width: 640px) {
        flex-direction: row;
        max-width: none;

        .meta {
            flex-basis: 40%;
            height: auto;
            flex-basis: 40%;
            height: auto;
            padding: 3rem 1rem;
            justify-content: flex-end;
            align-items: baseline;
            display: flex;
            flex-direction: column;
            color:$gray-500;

            &:before {
            transform: skewX(3deg);
            content: "";
            background: #ffffff;
            width: 30px;
            position: absolute;
            right: -14px;
            top: 0;
            bottom: 0;
            z-index: -1;
            }
        }

        .form {
            padding: 1rem;
            max-width: 700px;
            flex-basis: 60%;
        }
    }
}


.fiche-blog-card {
    .description {

        h4 {
            font-size: 1.1rem;
        }

        h5 {
            font-size: 0.8rem;
        }

        p {
            font-size: 0.9rem;
            position: relative;
            overflow: hidden;
            color:$gray-600;
        }

    }

    .photo{
    }

    &:hover {
    }

    @media (min-width: 640px) {
        max-width: initial;
        min-width: 100%;

        .meta {
            flex-basis: 25%;
        }

        .description {
            flex-basis: 75%;
        }
    }
}

.fiche-companies {
    border-radius: 15px;
    display:block;
    background-color: #fff;
    max-width: 100%;

    img{
        max-width: 300px;
        max-width: calc(100% - 40px);
        max-height: initial;
        margin: 20px;
        box-shadow: 0 0 1rem rgb(0 0 0 / 15%);
    }

    .description {
        padding: 20px;
        h4 {
            line-height: 1;
            margin: 0;
            font-size: 1.1rem;
        }

        h5 {
            font-size: 0.8rem;
            font-weight: 300;
            text-transform: uppercase;
            color: $gray-500;
            margin-top: 5px;
            position: relative;

            &:before {
                content: "";
                position: absolute;
                height: 5px;
                background: $c2;
                width: 35px;
                bottom: -0.75rem;
                border-radius: 0px;
            }
        }


        p {
            font-size: 0.9rem;
            position: relative;
            overflow: hidden;
            color:$gray-600;
        }

        .btn{
            white-space: nowrap;
        }
    }

    &:hover {
    }

    @media (min-width: 992px) {
        display: flex;
        max-width: initial;
        min-width: 100%;
        img{
            max-height: 180px;
            min-width: 200px;
        }
    }
}
